import React, { useEffect, useState } from 'react'
import './style.css'
import { Button, Col,Form, Layout, Menu, Row } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Divider,Empty,Skeleton,Spin,message } from "antd/lib";
import { useNavigate} from 'react-router-dom'
import BuyTicketModal from './BuyTicketModal';
import ChooseTicket from './ChooseTicket';
import Checkout from './Checkout';

import { getPublicRequestApi,postRequestBodyRawApi } from '../../../../../api/commonRequest';
import StripePayment from '../../../Payment/Stripe/StripePayment';
import { getDeviceType } from '../../../../utility';
import { submitGuestRegistrationData } from '../CommonFunc';
import { ProcessingFee } from '../../../../../config';

const menu = (
    <Layout>
        <Content style={{ overflow: 'auto', height: '400px' }}>
            <Menu>
                <Menu.Item key="1">Option 1</Menu.Item>
                <Menu.Item key="2">Option 2</Menu.Item>
                <Menu.Item key="3">Option 3</Menu.Item>
                <Menu.Item key="1">Option 1</Menu.Item>
                <Menu.Item key="2">Option 2</Menu.Item>
                <Menu.Item key="3">Option 3</Menu.Item>
                <Menu.Item key="1">Option 1</Menu.Item>
                <Menu.Item key="2">Option 2</Menu.Item>
                <Menu.Item key="3">Option 3</Menu.Item>

            </Menu>
        </Content>
    </Layout>
);
const ls_deviceType = getDeviceType();

const BuyTicket = (lo_props) => {

    const {lb_modalVisible, setModalVisible, lo_eventDetails, lo_userDetails, lb_isGuest} = lo_props;
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [lb_loading, setLoading] = useState(false)
    const [li_step, setStep] = useState(1)
    const [la_bookedTickets, setBookedTickets] = useState([])
    const [lo_userData, setUserData] = useState({})
    const [lo_bookingBasicDetails, setBookingBasicDetails] = useState({"total_amount":0, "total_quantity":0})
    const [ld_bookingDate, setBookingDate] = useState(new Date().toISOString().slice(0, 10));
    //payment state
    const [lb_makePayment, setMakePayment] = useState(false)
    const [lo_orderData, setOrderData] = useState({})

    const la_ticketList = (lo_eventDetails?.tickets && lo_eventDetails.tickets.length > 0) ? lo_eventDetails.tickets : []

    const [lo_responseMessage, setResponseMessage] = useState({})
    
    const resetAllData = () => {
        setLoading(false)
        setBookedTickets([])
        setBookingBasicDetails({"total_amount":0, "total_quantity":0})
        setBookingDate(new Date().toISOString().slice(0, 10))
        form.resetFields();
        setResponseMessage({})
    }

    const setUserDetails = () => {
        const fullNamed = lo_userDetails?.user?.name ? lo_userDetails.user.name.split(" ") : ['',''];
        setUserData({
            "billing_first_name": fullNamed[0],
            "billing_last_name": fullNamed[1],
            "billing_email": lo_userDetails?.user?.email,
        })
    }
    const handleSubmitTicketDetails = async (lo_formData) => {
        lo_formData['ticket_list'] = [...la_bookedTickets];
        const lb_check = await checkTicketBeforeSubmit(lo_formData.ticket_list)
        if(lb_check) {
            setLoading(true)
            const lo_bookData = {...lo_formData, ...lo_bookingBasicDetails};
            lo_bookData['event_id'] = lo_eventDetails.id;
            lo_bookData['booking_date'] = new Date().toISOString().slice(0, 10);
            console.log('++++lo_bookData',lo_bookData);
            // if(lo_bookData.hasOwnProperty("is_absorb")) delete lo_bookData['is_absorb'];
            // if(lo_bookData.hasOwnProperty("sub_total")) delete lo_bookData['sub_total'];
            // if(lo_bookData.hasOwnProperty("total_processing_fee")) delete lo_bookData['total_processing_fee'];
            console.log('---lo_bookData',lo_bookData);
            // lo_bookData['booking_date'] = ld_bookingDate;
            // lo_bookData['total_amount'] = 4;
            // lo_bookData['total_quantity'] = 4;
            const lo_response = await postRequestBodyRawApi("book-ticket",lo_bookData)
            setLoading(false)
            if (lo_response.status == 'success') {
                const lo_orderResponse = lo_response?.booking ? lo_response.booking : {};
                localStorage.setItem('paymentDetail', JSON.stringify(lo_orderResponse));
                if (lo_orderResponse?.total_amount === 0) {
                    message.success("Ticket Booked Succesfully!");
                    navigate("/booking-details");
                    return;
                }
                setOrderData(lo_orderResponse)
                // form.resetFields()
                // setVisibleModal(false)
                resetAllData()
                // message.success("Ticket Booked Succesfully!")
                // navigate("/booking-details")
                setMakePayment(true)
            }else {
                message.error('Validation Error!!')
            }
        }else{
            message.error("error")
        }

        
    }

    const checkTicketBeforeSubmit = async (la_tickets) => {
        let la_validationMessages = [];
        for (let i = 0; i < la_tickets.length; i++) {
            const rowValue = la_tickets[i];
            
            // if(rowValue.hasOwnProperty("is_absorb_fee")) delete rowValue['is_absorb_fee'];
            // if(rowValue.hasOwnProperty("processing_fee")) delete rowValue['processing_fee'];
            // if(rowValue.hasOwnProperty("tk_price")) delete rowValue['tk_price'];

            let li_eventId = rowValue.id;
            let li_ticketId = rowValue.ticket_id;
            const lo_response = await getPublicRequestApi("ticket-availability/"+li_eventId+"/"+li_ticketId);
            if(lo_response.available_quantity == 0) la_validationMessages.push(rowValue.ticket_name)
        }
        if (la_validationMessages.length > 0) {
            message.error("error")
            // message.error(<>
            //     {
            //         la_validationMessages.map((err, inx) => <p key={inx}>{err}</p>)
            //     }
            // </>)
        }else{
            return true;
        }
    }

    const setPriceAndQty = () => {
        let li_isabsorb = true;
        let lo_sum = la_bookedTickets?.reduce((total, rowValue) => {
            if(li_isabsorb) li_isabsorb = (rowValue?.is_absorb_fee || rowValue.price == 0) || false;
            return ({
                total_amount: total?.total_amount + (rowValue.price*rowValue.qty),
                sub_total: total?.sub_total + (rowValue.tk_price*rowValue.qty),
                // total_processing_fee: total?.total_processing_fee + ((rowValue?.is_absorb_fee ? 0 : rowValue.processing_fee)*rowValue.qty),
                total_quantity: total?.total_quantity + rowValue.qty
    
            })
        }, {total_amount: 0, total_quantity: 0, sub_total: 0, total_processing_fee:0});
        const li_procFee = (li_isabsorb || lo_sum.total_amount == 0) ? 0 : ProcessingFee;
        const totalAmnt = Math.round((lo_sum?.total_amount+li_procFee) * 100) / 100;
        const subtotalAmnt = Math.round((lo_sum?.sub_total) * 100) / 100;
        const totalProcngFeeAmnt = Math.round((totalAmnt-subtotalAmnt) * 100) / 100;
        lo_sum["total_amount"] = totalAmnt;
        lo_sum["total_processing_fee"] = Math.round((totalProcngFeeAmnt) * 100) / 100;
        lo_sum["sub_total"] = subtotalAmnt;
        lo_sum["is_absorb"] = li_isabsorb;
        setBookingBasicDetails(lo_sum)
    }

    const handlePlaceOrder = () => {
        form.validateFields().then((values) => {
            if (!values?.diclaration) {
                setResponseMessage({'diclaration':['You have to check!']}); return;
            }
            // form.resetFields();
            handleSubmitTicketDetails(values);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    }

    const handlePlaceOrderAsGuest = () => {
        form.validateFields().then(async (values) => {
            if (!values?.diclaration) {
                setResponseMessage({'diclaration':['You have to check!']}); return;
            }

            setLoading(true)
            const lo_regData = {
                "name": values?.billing_first_name+' '+values?.billing_last_name, "email": values?.billing_email, "password": values?.password, "password_confirmation": values?.password
            }
            const regResult = await submitGuestRegistrationData(lo_regData);
            if (regResult.status == 'success') {
                handleSubmitTicketDetails(values);
                setResponseMessage({})
            }else{
                setLoading(false)
                if (regResult?.message) {
                    setResponseMessage(regResult.message)
                    const lo_mssg = regResult?.message;
                    if (lo_mssg['name'][0]) {
                        message.error(lo_mssg['name'][0])
                    }else if (lo_mssg['email'][0]) {
                        message.error(lo_mssg['email'][0])
                    }else if (lo_mssg['password'][0]) {
                        message.error(lo_mssg['password'][0])
                    }else if (lo_mssg['password_confirmation'][0]) {
                        message.error(lo_mssg['password_confirmation'][0])
                    }
                }
            }
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    }

    useEffect(() => {
        console.log('----la_bookedTickets-',la_bookedTickets);
        la_bookedTickets?.length > 0 && setPriceAndQty()
    }, [JSON.stringify(la_bookedTickets)])
    

    useEffect(() => {
        setUserDetails()
    }, [lo_userDetails])

    const handleGoToCheckout = () => {
        if (!ld_bookingDate) {
            message.error('Choose Date!');
            return
        }
        if (!la_bookedTickets || la_bookedTickets.length == 0) {
            message.error('Choose a ticket!');
            return
        }
        form.setFieldsValue({ diclaration: true })
        setStep(2)
    }

    const lo_chooseTicketProps = {setStep, la_ticketList, ld_bookingDate, setBookingDate, lo_eventDetails, la_bookedTickets, setBookedTickets};
    const lo_checkoutProps = {li_step, setStep, form, lb_loading, la_bookedTickets, setBookedTickets, setUserData, lo_userData, lo_eventDetails, handleSubmitTicketDetails, ld_bookingDate, lo_userDetails, lb_isGuest, lo_responseMessage}
    const lo_placeOrderProps = {setStep, menu, lo_eventDetails, la_bookedTickets, setBookedTickets, lo_userData};
    const lo_paymentProps = {amount:lo_orderData?.total_amount, order_id:lo_orderData?.id, lo_userDetails}
    return (
    <>
            <BuyTicketModal modalVisible={lb_modalVisible}
                onCancel={() => setModalVisible(false)}
                title={lo_eventDetails.name}
            > 
            {/* <Layout> */}
               <Content style={{ overflow: 'auto',  }}>
                    {!lb_makePayment && lo_bookingBasicDetails && 
                    <Col span={24}>
                        <Spin spinning={lb_loading}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={18}>
                                {li_step == 1 ?
                                <ChooseTicket {...lo_chooseTicketProps}/>
                                : (li_step == 2 ? 
                                    <Checkout {...lo_checkoutProps}/> : 
                                        <>
                                            {/* <PlaceOrder {...lo_placeOrderProps}/> */}
                                        </>
                                    )} 
                            </Col>
                            
                                {
                                    la_bookedTickets?.length > 0 ? 
                                    // <Col xs={24} sm={24} md={24} lg={6} style={{ display: 'flex', alignItems: 'center', padding: "0px 25px 25px 25px",  overflow: 'auto', }}>
                                    <Col xs={24} sm={24} md={24} lg={6} className='ordr-summry-wrapper' style={ls_deviceType == 'xs' ? {paddingTop:8} : {} }>
                                        <div style={{ width: '100%' }} className='forminputprnt'>
                                            <Spin spinning={lb_loading}>
                                                {lb_loading? 
                                                    <Col span={24}><Skeleton /></Col>
                                                    :
                                                    <Col span={24} style={{marginBottom:30}}>

                                                        {/* <Col  xs={0} sm={0} md={0} lg={24}>
                                                            <Divider orientation="left" plain 
                                                            className='divider'
                                                            ></Divider>
                                                        </Col>   
                                                        <h3 className='mainTextRightSight'>
                                                        {lo_eventDetails.name}
                                                        </h3>
                                                        <Col xs={0} sm={0} md={0} lg={24}>
                                                        <Divider orientation="left" plain></Divider>
                                                        </Col> */}

                                                        <Divider style={{padding:0, margin:'0px 0px 6px 0px'}} orientation="left" plain><h3> Order Summary  <span style={{color:"#ad733ad1", fontSize:11}}>({lo_eventDetails.name})</span> </h3> </Divider>

                                                        {/* <>
                                                        {la_bookedTickets?.length ? <Divider style={{padding:0, margin:'0px 0px 6px 0px'}} orientation="left" plain></Divider> : <></>}
                                                        {
                                                            la_bookedTickets?.map((rowValue, inx) => (<div key={inx} className='tkt-summery-text'>
                                                                <h4 style={{maxWidth:180}}>{rowValue.qty} x {rowValue.ticket_name}</h4>
                                                                <h4>£ {rowValue.tk_price}</h4>
                                                            </div>))
                                                        }
                                                         <Divider style={{padding:0, margin:'0px 0px 6px 0px'}} orientation="left" plain></Divider>
                                                        <div className='tkt-summery-text'>
                                                            <h4>Total QTY</h4>
                                                            <h4>{lo_bookingBasicDetails?.total_quantity}</h4>
                                                        </div>
                                                        </> */}

                                                        <div className='tkt-summery-text'>
                                                            <h4>Subtotal</h4>
                                                            <h4>£ {lo_bookingBasicDetails?.sub_total}</h4>
                                                        </div>
                                                        {lo_bookingBasicDetails?.is_absorb ? <></> : 
                                                        <div className='tkt-summery-text'>
                                                            <h4>Fees</h4>
                                                            <h4>£ {lo_bookingBasicDetails?.total_processing_fee}</h4>
                                                        </div>}
                                                        <Divider style={{padding:0, margin:'0px 0px 6px 0px'}} orientation="left" plain></Divider>
                                                        <div className='tkt-summery-text-total'>
                                                            <h3>Total</h3>
                                                            <h3>£ {lo_bookingBasicDetails?.total_amount}</h3>
                                                        </div>
                                                        {/* <Divider style={{padding:0, margin:'0px 0px 30px 0px'}} orientation="left" plain></Divider> */}
                                                    </Col>
                                                }
                                                
                                            </Spin>
                                        
                                        </div>
                                    </Col>
                                    :
                                    <Col xs={24} sm={24} md={24} lg={6} style={{ display: 'flex', alignItems: 'center', padding: 0, justifyContent:"center", overflow: 'auto', }}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Empty Cart" style={{padding:0, margin:"20px 0px 0px 0px"}} />
                                        {/* <Row>
                                            <Col>
                                            <CalendarOutlined size={50} />
                                            </Col>
                                            <h5 style={{marginInlineStart:10}}>Select an event</h5>
                                        </Row> */}
                                    </Col>
                                }
                            
                        
                            <Col xs={24} sm={24} md={24} lg={24} >
                                

                                {li_step == 1 ?
                                <Button
                                    className="add-btn button"
                                    type="default"
                                    style={{ float: "right", width: ls_deviceType == 'xs' ? '100%' : 109, height: 38,
                                    marginTop:((la_bookedTickets?.length == 0 || ls_deviceType == 'md')) ? 20: -50 }}
                                    onClick={() => handleGoToCheckout()}
                                >
                                    Checkout
                                </Button>
                                : (li_step == 2 ? <Button
                                    loading={lb_loading}
                                    className="add-btn button"
                                    type="default"
                                    style={{ float: "right", width: ls_deviceType == 'xs' ? '100%' : 109,height: 38,marginTop:((la_bookedTickets?.length == 0 || ls_deviceType == 'md')) ? 20: -50 }}
                                    onClick={() => lb_isGuest ?  handlePlaceOrderAsGuest() : handlePlaceOrder() }
                                >
                                    Place Order
                                </Button> : <></>)}
                            </Col>
                            
                        </Row>
                        </Spin>
                    </Col>
                    }

                    {(lb_makePayment && lo_orderData?.total_amount && lo_orderData?.id) ? 
                    <Col span={24}>
                        <StripePayment {...lo_paymentProps}/>
                    </Col> : <></>
                    }
                    </Content>
                {/* </Layout> */}
            </BuyTicketModal>
    </>
  )
}

export default BuyTicket